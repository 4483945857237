import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import mySVG from '../static/images/Vector.svg'
import Button from 'react-bootstrap/Button';

function CollectionPage() {
  return (
    <section className='darkblue' id='collections'>
      <Row className='no-pad'>
          <Col xs={{span: 12, order: 1}} md={{span: 4, order:1}} className="dark no-pad">
            <div className='info-wrapper col-w'>
              <p className='info-heading'>SHOP THE COLLECTION</p>
              <p className='info-title'>I Like You, <br></br> You're Weird <span className='tag'>(ILYYW)</span></p>
              <p className='info-desc'>
              A community-driven collectibles project featuring art by Amber Park and Mason Rothschild. Across the 7,800 Weirdos in our collection, no two are the same - much like humankind itself.
              </p>
              <p className='collection-supply'>7,800 Weirdos</p>
              <a href='https://opensea.io/collection/ilyyw' target="_blank" >
              <button className='info-button' > 
                Buy a Weirdo &nbsp;
                <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="16px" height="16px">
                  <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 5 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z"/>
                </svg>
              </button>
              </a>
            </div>
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 4, order:2}} className="bgbg2"></Col>
          <Col xs={{span: 12, order: 3}} md={{span: 4, order:3}} className="dark no-pad">
            <div className='info-wrapper col-s'>
                <p className='info-heading'><br></br></p>
                <p className='info-title'>I Hate You, <br></br> You're Scary <span className='tag'>(IHYYS)</span></p>
                <p className='info-desc'>
                The Big Burn minted Scaries that roam on the other side of the Green Gas. This is a collection of 2,200 Scaries that serve as
                the antagonists to the Weirdos.  
                </p>
                <p className='collection-supply'>2,200 Scaries</p>
                <a  href='https://opensea.io/collection/ihyys' target="_blank"> 
                <button className='info-button'> Buy a Scary &nbsp;
                <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="16px" height="16px">
                  <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 5 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z"/>
                </svg>
                 </button>
                 
                </a>
              </div>
          </Col>
      </Row>
    
    </section>
  );
}

export default CollectionPage;