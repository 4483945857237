
import React from 'react';
import { Container } from 'react-bootstrap'
import Dashboard_Select_Team from './Dashboard_Select_Team';

function Dashboard() {
    const ethereum = (window).ethereum;
    if (ethereum) {
        if (!ethereum.selectedAddress) {
            //await ethereum.enable(); // <<< ask for permission
            localStorage.removeItem("isAuthenthicated");
            window.location.href = "/";
        }
    }   
    else {
        
    }

    return (
      <Container fluid className='p-0'>
        <Dashboard_Select_Team/>
      </Container>
    )
  }
  
  export default Dashboard