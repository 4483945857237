import React from "react";
import { Navigate, Route, Outlet } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  let isAuthenticated = true;
  
  const ethereum = (window).ethereum;

  function handleAccountsChanged(accounts) {
    if (accounts.length === 0) {
      isAuthenticated = false;
    }
  }

  ethereum.on('accountsChanged', handleAccountsChanged);
  
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
}

export default ProtectedRoute;