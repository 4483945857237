import React from 'react'
import {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import Image from 'react-bootstrap/Image'
import img1 from '../static/images/battle_key_img.png'
import AccordionItem from 'react-bootstrap/esm/AccordionItem';


function Mechanics() {
    const [buttonText, setButtonText] = useState('Expand');
    function handleClick() {
        var t = (buttonText == "Expand") ? "Collapse" : "Expand";
        setButtonText(t);
        var arrList = Array.from(document.querySelectorAll('.accordion-button'));
        arrList.forEach(element => {
            element.click();
        });  
      }
return (
    <section className='bg4'>
      <Row className='no-pad'>
          <Col xs={{span: 12, order: 1}} md={{span: 4, order:1}} className="no-pad">
            <div className='info-wrapper mech-wrap'>
              <p className='info-heading'>BATTLE KEY</p>
              <p className='info-title'>Game Mechanics</p>
              <p className='info-desc'>
              To participate in the Weirdos Versus Scaries tournament you must enter one Weirdo and one Scary to create a pair. You may enter as many pairs as you’d like to increase your chances of winning the tournament or one of the fifty prizes in our Winner’s Circle.
                <br></br><br></br>
                Every Weirdo and Scary will have a power level based on the rarity of their head and body trait. For example, a Mud Head has a power level of +1 while a 24K Gold Head has a power level of +5. You can see a full key by selecting the Power Level Breakdown button below.
                <br></br><br></br>
                This tournament will put head trait against head trait and body trait against body trait. The greater the power level the greater the chance you will have at defeating your opponent. However, simply having a greater power level doesn’t automatically guarantee a win. If you’re trait is weak to your opponents trait, for example, in the case of fire versus water, you may lose power level points and ultimately lose the battle.  Since opponent’s are randomized you will not know your chance of winning
                until your opponent is named.
              </p>
              <div className='powerdiv'>
              <a href='https://docs.google.com/spreadsheets/d/1q4poExyHxUV0re8S0kqlpqhcwYpaGNvxqvkEW_6jV2E/edit#gid=0' target="_blank" >
              <button className='info-button-mechanics'> 
                Power levels breakdown &nbsp;
                <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="16px" height="16px">
                  <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 5 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z"/>
                </svg>
              </button>
              </a>
              </div>
              <div className='expand-div2'>
                    <button className='expand-all2' onClick={handleClick}> 
                        {buttonText} all
                        &nbsp;
                        <svg width="19" height="21" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.59 15.17L1.42 12L0.0100002 13.41L4.59 18L9.18 13.41L7.76 12M4.59 2.83L7.76 6L9.17 4.59L4.59 0L0 4.59L1.42 6L4.59 2.83Z" fill="#FFFFFF"/>
                        </svg>
                    </button>
                </div>
            </div>
            
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 4, order:2}} className="">         
            <div className='mech-wrap2'>
                    <div className="mechanics-center">
                    
                        <Accordion className='acc-mech'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>🌎 EARTH DEFEATS:</Accordion.Header>
                                <Accordion.Body>
                                    🌱 Grass<span></span>⚙️ Gear
                                </Accordion.Body>
                                <Accordion.Body>
                                    🔗 Metal<span></span>⚰️ In-Human

                                </Accordion.Body>
                               
                            </Accordion.Item>
                        </Accordion>
                        <Accordion className='acc-mech'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>🔥 FIRE DEFEATS:</Accordion.Header>
                                <Accordion.Body>
                                    🌱 Grass<span></span>⚙️ Gear
                                </Accordion.Body>
                                <Accordion.Body>
                                    🔗 Metal<span></span>⚰️ In-Human
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion className='acc-mech'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>🌱  GRASS DEFEATS:</Accordion.Header>
                                <Accordion.Body>
                                ❄️ Ice<span></span>🦠 Contaminated
                                </Accordion.Body>
                                <Accordion.Body>
                                💧 Water<span></span>🧪 Goo
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion className='acc-mech'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>❄️ ICE DEFEATS:</Accordion.Header>
                                <Accordion.Body>
                                    🌎 Earth<span></span>🍂 Mulch
                                </Accordion.Body>
                                <Accordion.Body>
                                    🔥 Fire<span></span>⚠️ Nuclear
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion className='acc-mech'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>🔗 METAL DEFEATS:</Accordion.Header>
                                <Accordion.Body>
                                ❄️ Ice<span></span>🦠 Contaminated
                                </Accordion.Body>
                                <Accordion.Body>
                                💧 Water<span></span>🧪 Goo
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion className='acc-mech'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>💧  WATER DEFEATS:</Accordion.Header>
                                <Accordion.Body>
                                🌎 Earth<span></span>🍂 Mulch
                                </Accordion.Body>
                                <Accordion.Body>
                                🔥 Fire<span></span>⚠️ Nuclear
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion></div>
                    
                </div>
          </Col>
          <div className='expand-div'>
          <button className='expand-all' onClick={handleClick}> 
                        {buttonText} all
                        &nbsp;
                        <svg width="19" height="21" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.59 15.17L1.42 12L0.0100002 13.41L4.59 18L9.18 13.41L7.76 12M4.59 2.83L7.76 6L9.17 4.59L4.59 0L0 4.59L1.42 6L4.59 2.83Z" fill="#FFFFFF"/>
                        </svg>
                    </button>
                </div>
          <Col xs={{span: 12, order: 3}} md={{span: 4, order:3}} className="">
                
            <div className='mech-wrap2'>
            <div className="mechanics-center">
                        <Accordion className='acc-mech'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header className='acc-mech'>🍂 MULCH DEFEATS:</Accordion.Header>
                                <Accordion.Body>
                                    🌱 Grass<span></span>⚙️ Gear
                                </Accordion.Body>
                                <Accordion.Body>
                                    🔗 Metal<span></span>⚰️ In-Human

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion className='acc-mech'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>⚠️ NUCLEAR DEFEATS:</Accordion.Header>
                                <Accordion.Body>
                                    🌱 Grass<span></span>⚙️ Gear
                                </Accordion.Body>
                                <Accordion.Body>
                                    🔗 Metal<span></span>⚰️ In-Human
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion className='acc-mech'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>⚰️ IN-HUMAN DEFEATS:</Accordion.Header>
                                <Accordion.Body>
                                ❄️ Ice<span></span>🦠 Contaminated
                                </Accordion.Body>
                                <Accordion.Body>
                                💧 Water<span></span>🧪 Goo
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion className='acc-mech'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>🦠 CONTAMINATED DEFEATS:</Accordion.Header>
                                <Accordion.Body>
                                    🌎 Earth<span></span>🍂 Mulch
                                </Accordion.Body>
                                <Accordion.Body>
                                    🔥 Fire<span></span>⚠️ Nuclear
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion className='acc-mech'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>⚙️ GEAR DEFEATS:</Accordion.Header>
                                <Accordion.Body>
                                ❄️ Ice<span></span>🦠 Contaminated
                                </Accordion.Body>
                                <Accordion.Body>
                                💧 Water<span></span>🧪 Goo
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion className='acc-mech'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>🧪 GOO DEFEATS:</Accordion.Header>
                                <Accordion.Body>
                                🌎 Earth<span></span>🍂 Mulch
                                </Accordion.Body>
                                <Accordion.Body>
                                🔥 Fire<span></span>⚠️ Nuclear
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion></div>
            </div>
          </Col>
      </Row>
    
    </section>
  )
}

export default Mechanics