import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import image1 from '../static/images/prize1.png'
import image2 from '../static/images/prize2.png'
import image3 from '../static/images/prize3.jpg'
import image4 from '../static/images/prize4.png'
import image5 from '../static/images/prize5.png'
import image6 from '../static/images/maxpainandfrens.png'
import image7 from '../static/images/ledgergenpass.png'
import image8 from '../static/images/cosmiccutie.png'
import image9 from '../static/images/arcadeland.png'
import image10 from '../static/images/dippie.png'
import image11 from '../static/images/weirdo744.png'
import image12 from '../static/images/weirdo3364.png'
import image13 from '../static/images/ts6467.png'
import image14 from '../static/images/ts6505.png'
import image15 from '../static/images/ts7807.png'
import image16 from '../static/images/spotthekekhirst.png'
import image17 from '../static/images/haus.png'
import image18 from '../static/images/qmark.png'

function PrizeList(props) {
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const data  = [
    {
      "name": "Doodle #6901",
      "placement": "1st place",
      "image": image1
    },
    {
      "name": "Invisible Friend #1280",
      "placement": "2nd place",
      "image": image2
    },
    {
      "name": "Otherdeed for Otherside #75336",
      "placement": "3rd place",
      "image": image3
    },
    {
      "name": "goblintown #1971",
      "placement": "4th place",
      "image": image4
    },
    {
      "name": "Murakami Flower #8138",
      "placement": "5th place",
      "image": image5
    },
    {
      "name": "MAX PAIN AND FRENDS BY XCOPY",
      "placement": "6th place",
      "image": image6
    },
    {
      "name": "MAX PAIN AND FRENDS BY XCOPY",
      "placement": "7th place",
      "image": image6
    },
    {
      "name": "MAX PAIN AND FRENDS BY XCOPY",
      "placement": "8th place",
      "image": image6
    },
    {
      "name": "MAX PAIN AND FRENDS BY XCOPY",
      "placement": "9th place",
      "image": image6
    },
    {
      "name": "MAX PAIN AND FRENDS BY XCOPY",
      "placement": "10th place",
      "image": image6
    },
    {
      "name": "[Ledger] Market Pass- Genesis Edition",
      "placement": "11th place",
      "image": image7
    },
    {
      "name": "[Ledger] Market Pass- Genesis Edition",
      "placement": "12th place",
      "image": image7
    },
    {
      "name": "[Ledger] Market Pass- Genesis Edition",
      "placement": "13th place",
      "image": image7
    },
    {
      "name": "[Ledger] Market Pass- Genesis Edition",
      "placement": "14th place",
      "image": image7
    },
    {
      "name": "[Ledger] Market Pass- Genesis Edition",
      "placement": "15th place",
      "image": image7
    },
    {
      "name": "Cosmic Cutie #72",
      "placement": "16th place",
      "image": image8
    },
    {
      "name": "TBA",
      "placement": "17th place",
      "image": image18
    },
    {
      "name": "TBA",
      "placement": "18th place",
      "image": image18
    },
    {
      "name": "TBA",
      "placement": "19th place",
      "image": image18
    },
    {
      "name": "TBA",
      "placement": "20th place",
      "image": image18
    },
    {
      "name": "SPOT THE KEKHIRST",
      "placement": "21st place",
      "image": image16
    },
    {
      "name": "TBA",
      "placement": "22nd place",
      "image": image18
    },
    {
      "name": "Arcade Land X-Large #1005",
      "placement": "23rd place",
      "image": image9
    },
    {
      "name": "TBA",
      "placement": "24th plcae",
      "image": image18
    },
    {
      "name": "Dippie #6030",
      "placement": "25th place",
      "image": image10
    },
    {
      "name": "Weirdo #744",
      "placement": "26th place",
      "image": image11
    },
    {
      "name": "Weirdo #3364",
      "placement": "27th place",
      "image": image12
    },
    {
      "name": "Test Subject #6467",
      "placement": "28th place",
      "image": image13
    },
    {
      "name": "Test Subject #6505",
      "placement": "29th place",
      "image": image14
    },
    {
      "name": "Test Subject #7807",
      "placement": "30th place",
      "image": image15
    },
    {
      "name": "hausphases by Haus",
      "placement": "31st place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "32nd place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "33rd place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "34th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "35th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "36th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "37th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "38th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "39th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "40th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "41st place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "42nd place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "43rd place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "44th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "45th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "46th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "47th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "48th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "49th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "50th place",
      "image": image17
    }
  ];

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const handleClose = () => setShow(false);

  return (
    <>
    
      {values.map((v, idx) => (
      <button key={idx} onClick={handleShow} className='info-button'>View all prizes
        {typeof v === 'string' && `below ${v.split('-')[0]}`}
      </button>
      ))}
      

      <Modal 
      {...props}
      size="xl"
      show={show} 
      fullscreen={fullscreen} 
      onHide={() => setShow(false)}
      centered
      className='no-pad'
      >
        <Modal.Header className='modal-prize-header'>
          
          <div className='mybut'>
          <button onClick={handleClose} className='close-button'>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black" />
      </svg>

          </button>
        </div>
        </Modal.Header>      
        <Modal.Body className='modal-prizes no-pad'>
          <h2 className='modal-title'>Tournament Prizes</h2>
          <Row className='test no-pad'>
            <Col sm={5} className=' prize-col'>
            {data.map((name, index) => {
              if(index < 25)
                return (
                  <div className='prize-div' key={index.toString()}>
                    <div className='prize-img-wrapper'>
                      <Image className='prize-img' src={name.image}/>
                    </div>
                    <div className=' prize-info'>
                      <div className=' prize-place'>
                      <p style={{fontSize: 12}}>{name.placement}</p>
                      </div>
                      <div className=' prize-name'>
                        <h4 style={{fontWeight: 600, color: '#c0c0c0'}}>{name.name}</h4>
                      </div>
                    </div>
                  </div>
                  
                )
            })}
            </Col>
            <Col sm={5} className=' prize-col'>
            {data.map((name, index) => {
              if(index >= 25)
                return (
                  <div className='prize-div' key={index.toString()}>
                    <div className='prize-img-wrapper'>
                      <Image className='prize-img' src={name.image}/>
                    </div>
                    <div className='prize-info'>
                      <p style={{fontSize: 12, paddingBottom: 8}}>{name.placement}</p>
                      <h4 style={{fontWeight: 600, fontSize: 24, color: '#c0c0c0'}}>{name.name}</h4>
                    </div>
                  </div>
                )
            })}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PrizeList;