import './App.css';
import Container from 'react-bootstrap/Container';
import React,{useState,useEffect} from 'react';
import Dashboard from './components/Dashboard';
import useMetaMask from './hooks/metamask';
import ProtectedRoute from './components/ProtectedRoute';
import { registerUser } from './services/auth';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './components/Home';
import Spinner from './components/SpinnerScreen'




function App() {

  
  const getData=()=>{
    fetch('data.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        return response.json();
      })
      .then(function(myJson) {
        if(sessionStorage.getItem("content") == null){
          sessionStorage.setItem("content", JSON.stringify(myJson));
        }
        
      });
  }

  useEffect(()=>{
    getData()
    registerUser()
  },[])

  return (
    <Container fluid className='p-0'>
      <Router>
        <Routes>
          <Route exact path='/dashboard' element={<ProtectedRoute/>}>
            <Route exact path='/dashboard' element={<Dashboard/>}/>
          </Route>
          <Route exact path='/' element={<Home/>}/>
        </Routes>
      </Router>
    </Container>
  );
}

export default App;
