import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function PreBracket(){
    var user = sessionStorage.getItem("wallet");
  
  
    const loadEffects = () => {
        var arr = document.getElementsByClassName("participant");
        var arr2 = document.getElementsByClassName("participantl");
        for(var i = 0; i < arr.length; i++){
            if(arr[i].getAttribute("wallet") == user.toLowerCase()){
                arr[i].className="participant-selected";
            }
        }
        for(var i = 0; i < arr2.length; i++){
            if(arr2[i].getAttribute("wallet") == user.toLowerCase()){
                arr2[i].className="participant-elim";
            }
        }
    }
  
    useEffect(()=>{
      loadEffects();
    },[])

  return (
    <Container fluid className='p-0'>
    <Row className='no-pad bracket-row'>
      <div className='wrapper-box '>
            <div className='final-round-wrap '>
              <div className='trophy-space'>
                <svg className='trophy' width="62" height="52" viewBox="0 0 85 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M74.2441 3.63852C71.6002 3.05594 68.547 3.42955 65.6724 4.50923C65.6972 2.96159 65.6896 1.45007 65.6458 0H16.3546C16.3107 1.44948 16.3025 2.96108 16.3279 4.50923C13.4533 3.42955 10.4008 3.05595 7.75627 3.63852C3.84581 4.4953 1.19057 7.17331 0.280436 11.1722C-0.908664 16.3933 1.70913 22.3793 7.6502 28.0299C12.3315 32.481 18.612 36.1842 25.2444 38.4714C27.9926 42.3315 31.5103 45.4414 35.9736 47.3323C35.3228 49.7005 33.0387 56.5618 27.5196 60.7607H54.4804C48.96 56.5624 46.6766 49.7005 46.0264 47.3323C50.4899 45.4414 54.0087 42.3309 56.7556 38.4701C63.3875 36.1835 69.6685 32.4803 74.3498 28.0293C80.2915 22.3783 82.9086 16.3929 81.7196 11.1716C80.8082 7.17331 78.1535 4.49527 74.2431 3.63852H74.2441ZM11.6051 23.9022C7.30822 19.8133 5.16255 15.5283 5.86671 12.4367C6.28046 10.6116 7.27316 9.58776 8.98971 9.21037C11.2555 8.71202 14.2114 9.46938 16.6461 10.9696C17.1991 17.5141 18.4968 24.3486 20.9246 30.4129C17.431 28.6025 14.2216 26.3912 11.6051 23.902V23.9022ZM70.3946 23.9022C67.7781 26.3902 64.5692 28.6027 61.0751 30.4138C63.5042 24.3499 64.8012 17.5147 65.3536 10.9704C67.7883 9.47088 70.7436 8.71351 73.01 9.21119C74.7266 9.58859 75.7199 10.6125 76.133 12.4375C76.8372 15.5295 74.6916 19.8147 70.3946 23.9023V23.9022Z" fill="white"/>
                    <path d="M23.6836 64.543H58.3059V72H23.6836V64.543Z" fill="white"/>
                </svg>
              </div>
              <p className='final-round'>The Final Winner</p>
                <div className='centered'>
                  <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/1298.png" className="final-img"></img>
                  <p className='final-name'>Scary #1298</p>
                </div>
            </div>
        </div>    
      <Col className='centered'>
        <div className='bracket'>
          <section className="round quarterfinals">
            <div className="winners">
              <div className="matchups match1">
                <h3 className='round-header'>Round 9</h3>
                <div className="matchup">
                  <div className="participants">
                    <div className="participant" wallet='0x21c015e54b10d3afab9fd034d0cc29a6e0bbf547'>
                      <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/1298.png" className="img-box"></img>
                      <p className='img-name'>Scary #1298</p>
                    </div>
                    <div className="participantl" wallet="0xd1a1b1129e48be460bc27b89f04bb586f3110bc3">
                    <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/1485.png" className="img-box"></img>
                      <p className='img-name'>Scary #1485</p>
                    </div>
                  </div>
                </div>
                <div className="matchup">
                  <div className="participants">
                    <div className="participant" wallet="0x064c91fc201915d38b05e73d5272ad8986d05920">
                    <img src="https://ipfs.io/ipfs/QmcUxPUgzx9sQRimX2pRGKjrCzQGbLkfL4muAhZMWA3tR5/1093.png" className="img-box" id='123445'></img>
                      <p className='img-name'>Weirdo #1093</p>
                    </div>
                    <div className="participantl" wallet="0x3371cdfdeef46cc6db8e5356bf06c22167e883d8">
                    <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/1083.png" className="img-box"></img>
                      <p className='img-name'>Scary #1083</p>
                      </div>
                  </div>
                </div>
              </div>
              <div className="connector">
                <div className="merger"></div>
                <div className="line"></div>
              </div>
            </div>
            <div className="winners">
              <div className="matchups">
                <div className="matchup">
                  <div className="participants">
                    <div className="participant">
                    <div className="img-box-pre"></div>
                    <p className='img-name'>?</p>
                    </div>
                    <div className="participant">
                    <div className="img-box-pre"></div>
                    <p className='img-name'>?</p>
                    </div>
                  </div>
                </div>
                <div className="matchup">
                  <div className="participants">
                    <div className="participant">
                    <div className="img-box-pre"></div>
                    <p className='img-name'>?</p>
                    </div>
                    <div className="participant">
                    <div className="img-box-pre"></div>
                    <p className='img-name'>?</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="connector">
                <div className="merger"></div>
                <div className="line"></div>
              </div>
            </div>
          </section>
          <section className="round semifinals">
            <div className="winners">
              <div className="matchups match2">
              <h3 className='round-header'>Round 10</h3>
                <div className="matchup">
                  <div className="participants">
                    <div className="participant" wallet='0x21c015e54b10d3afab9fd034d0cc29a6e0bbf547'>
                    <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/1298.png" className="img-box"></img>
                      <p className='img-name'>Scary #1298</p>
                    </div>
                    <div className="participantl" wallet="0x064c91fc201915d38b05e73d5272ad8986d05920">
                    <img src="https://ipfs.io/ipfs/QmcUxPUgzx9sQRimX2pRGKjrCzQGbLkfL4muAhZMWA3tR5/1093.png" className="img-box" id='123445'></img>
                      <p className='img-name'>Weirdo #1093</p>
                    </div>
                  </div>
                </div>
                <div className="matchup">
                  <div className="participants">
                    <div className="participant">
                    <div className="img-box-pre"></div>
                    <p className='img-name'>?</p>
                    </div>
                    <div className="participant">
                    <div className="img-box-pre"></div>
                    <p className='img-name'>?</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="connector connector-space">
                <div className="merger"></div>
                <div className="line"></div>
              </div>
            </div>
          </section>
          <section className="round finals">
            <div className="winners">
              <div className="matchups">
              <h3 className='round-header'>Round 11</h3>
                <div className="matchup">
                  <div className="participants">
                  <div className="participant" wallet='0x21c015e54b10d3afab9fd034d0cc29a6e0bbf547'>
                    <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/1298.png" className="img-box"></img>
                      <p className='img-name'>Scary #1298</p>
                    </div>
                    <div className="participant">
                    <div className="img-box-pre"></div>
                    <p className='img-name'>BYE</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Col>
      <Col className='reverse centered'>
      <div className='bracket'>
          <section className="round quarterfinals">
            <div className="winners">
              <div className="matchups match1">
              <h3 className='round-header2'>Round 9</h3>
                <div className="matchup">
                  <div className="participants">
                     <div className="participant" wallet="0x21c015e54b10d3afab9fd034d0cc29a6e0bbf547">
                    <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/1301.png" className="img-box"></img>
                      <p className='img-name2'>Scary #1301</p>    
                    </div>
                    <div className="participantl" wallet="0x3738083ae9cf6316f7edad5375744e82c482c5ae">
                    <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/52.png" className="img-box"></img>
                      <p className='img-name2'>Scary #52</p>             
                    </div>
                  </div>
                </div>
                <div className="matchup">
                  <div className="participants"><div className="participant" wallet="0x4c6bd41a465e7ad89af02617e9cd107e26b262c6">
                    <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/119.png" className="img-box"></img>
                      <p className='img-name2'>Scary #119</p>                  
                    </div>
                    <div className="participantl" wallet="0x78dd76a13b15deddebad424d76a66bbb7a60213e">
                    <img src="https://ipfs.io/ipfs/QmcUxPUgzx9sQRimX2pRGKjrCzQGbLkfL4muAhZMWA3tR5/4752.png" className="img-box"></img>
                      <p className='img-name2'>Weirdo #4752</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="connector">
                <div className="merger"></div>
                <div className="line"></div>
              </div>
            </div>
            <div className="winners">
              <div className="matchups">
                <div className="matchup">
                  <div className="participants">
                    <div className="participant" wallet="0x837253fa2d3f43a07dd8a8ce2c081aad8f670d5d">
                    <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/915.png" className="img-box"></img>
                      <p className='img-name2'>Scary #915</p>
                    </div>
                    <div className="participantl" wallet="0x317c8271c56db85f9e1c0e5b3cfb915f683f11b5">
                    <img src="https://ipfs.io/ipfs/QmcUxPUgzx9sQRimX2pRGKjrCzQGbLkfL4muAhZMWA3tR5/3478.png" className="img-box"></img>
                      <p className='img-name2'>Weirdo #3478</p>
                    </div>
                  </div>
                </div>
                <div className="matchup">
                  <div className="participants"><div className="participant" wallet="0xcc99e8f483d907f1c32aee0673ac78600da8b2af">
                    <img src="https://ipfs.io/ipfs/QmcUxPUgzx9sQRimX2pRGKjrCzQGbLkfL4muAhZMWA3tR5/5951.png" className="img-box"></img>
                      <p className='img-name2'>Weirdo #5951</p>
                    </div>
                    <div className="participantl" wallet="0xef24a8a2a67228c4e912df8e73aa026cfe855988">
                    <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/1274.png" className="img-box"></img>
                      <p className='img-name2'>Scary #1274</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="connector">
                <div className="merger"></div>
                <div className="line"></div>
              </div>
            </div>
          </section>
          <section className="round semifinals">
            <div className="winners">
              <div className="matchups match2">
              <h3 className='round-header2'>Round 10</h3>
                <div className="matchup">
                  <div className="participants"><div className="participant" wallet="0x4c6bd41a465e7ad89af02617e9cd107e26b262c6">
                    <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/119.png" className="img-box"></img>
                      <p className='img-name2'>Scary #119</p> 
                      </div>
                        <div className="participantl" wallet="0x21c015e54b10d3afab9fd034d0cc29a6e0bbf547">
                        <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/1301.png" className="img-box"></img>
                          <p className='img-name2'>Scary #1301</p>
                      </div>
                  </div>
                </div>
                <div className="matchup">
                  <div className="participants">
                    <div className="participant" wallet="0x837253fa2d3f43a07dd8a8ce2c081aad8f670d5d">
                    <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/915.png" className="img-box"></img>
                      <p className='img-name2'>Scary #915</p>
                    </div>
                    <div className="participantl" wallet="0xcc99e8f483d907f1c32aee0673ac78600da8b2af">
                    <img src="https://ipfs.io/ipfs/QmcUxPUgzx9sQRimX2pRGKjrCzQGbLkfL4muAhZMWA3tR5/5951.png" className="img-box"></img>
                      <p className='img-name2'>Weirdo #5951</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="connector connector-space">
                <div className="merger"></div>
                <div className="line"></div>
              </div>
            </div>
          </section>
          <section className="round finals">
            <div className="winners">
              <div className="matchups">
              <h3 className='round-header2'>Round 11</h3>
                <div className="matchup">
                  <div className="participants">
                  <div className="participant" wallet="0x4c6bd41a465e7ad89af02617e9cd107e26b262c6">
                    <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/119.png" className="img-box"></img>
                      <p className='img-name2'>Scary #119</p>
                    </div>
                    <div className="participantl" wallet="0x837253fa2d3f43a07dd8a8ce2c081aad8f670d5d">
                    <img src="https://ihyys2500.s3.us-west-1.amazonaws.com/thumbnail/915.png" className="img-box"></img>
                      <p className='img-name2'>Scary #915</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Col>
    </Row>
  </Container>
  )

}

export default PreBracket;