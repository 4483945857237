import { useState } from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from './Pagination';
import React, { useEffect } from 'react';
import {Rounds, round_number} from './rounds';

function RoundList() {
  const [postsPerPage] = useState([100]);

  const [players, setPlayers] = useState([]);
  const [winner, setWinner] = useState([]);

  const getRanking =(rank)=>{
    let listItems = [];
    
    if(rank.length > 0){
        rank.map((obj) => {
      
            listItems.push(
                {
                    name: obj.name,
                    ranking: obj.Rank,
                    address: obj.eth_address
                }
            )
            });
        setWinner(listItems);   
    }
}

  const loadRounds = () =>{
    fetch(Rounds())
         .then((response) => {
             return response.json();
         })
         .then((actualData) => {
          //console.log(actualData.slice(0, 100))
            // paintHTML(actualData.slice(0, 100));
            setPlayers(actualData);
         })
         .catch((err) => {
             console.log(err.message);
         }
     );
    
  } 

  let ranks = [];
  const loadRanks = () =>{
    fetch('./data/ranks.json').then(function(response){
               return response.json();
           }).then(function(myJson) {
               ranks = myJson; 
               getRanking(ranks);
           });
    } 

  //  const paintHTML = (arr) => {
  //   let html = "";
  //   arr.map((name) => {
  //     html += `<tr class='battles-div'>`
  //     html += `<td class='rounds'>${name.winner}<em class='rounds-desc'>&nbsp; <span class='battle-text'>battles</span> &nbsp;</em>${name.loser}</td>`;
  //     html += `<td class='green-text'>🎉${name.winner} - WINS🎉</td>`;
  //     html += `</tr>`;
  //   });

  //    document.getElementById("divList").innerHTML = html;

  //  } 
   
    useEffect(()=>{
      loadRounds();
      loadRanks();
    },[])


    // const paginate = (pageNumber) => {
    //   //setCurrentPage(pageNumber);

    //   let y = (pageNumber * postsPerPage);
    //   let x = (pageNumber * postsPerPage) - postsPerPage;
      
    //   paintHTML(players.slice(x, y));
    // }

  return (
    <Container className='centered bracket-sect'>
        
        <div className='bracket-div centered'>
          <div className='title-wrap'>
          <p className='main-title'>Top 50 Winners</p>
          </div>
        <Table className='bracket-table'>
        <thead className='header-f'>
        <tr>
          <th className='t-font1'>Rank</th>
          <th className='t-font'>Winners</th>
          <th className='t-font'>Address</th>
        </tr>
      </thead>
      <tbody className='my-test' id="divList">
      {winner.map((name, index) => {
             
             return (
              <tr className='' key={index.toString()}>
                 <td className='rounds centered'>{name.ranking}</td>
                 <td className='green-text'>🎉{name.name}🎉</td>
                 <td className=''>{name.address}</td>
              </tr>

             )
         })} 

     </tbody>
      </Table>
        <a className='info-button previous-rounds' href='https://docs.google.com/spreadsheets/d/1dh-mOfZReeH_LSvXT36PSLElF26olreNx65OqJa9L8U/edit#gid=13434382' target="_blank" rel="noopener noreferrer">
            View previous battles &nbsp;
            <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="16px" height="16px">
                  <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 5 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z"/>
                </svg>
          </a>
      </div>
      
    </Container>

  );
}

export default RoundList;