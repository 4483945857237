import React, { Component, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';



function TournamentDetails() {

  const [isAuthenticated , setAuthenticated] = useState(false);
  const ethereum = (window).ethereum;
  const navigate = useNavigate();

  const handleConnect = () => {
    navigate("/dashboard");
    var address = ethereum.selectedAddress;
    sessionStorage.setItem("wallet", address)
    return;
    /*
    const isAuthenticated = localStorage.getItem("isAuthenticated");
      if(isActive && sessionStorage.getItem("account") !== null){
      
        console.log(isAuthenticated);
         if(isAuthenticated) {
          window.location.href = "/dashboard";
          //navigate("/dashboard");
        }  
      }
      else if(sessionStorage.getItem("account") == null){
        console.log("not active");
       
      }
      else{
        connect();
      }
      */
  }
  return (
    <section className='dark' id='tournament'>
      <Row className='no-pad'>
          <Col xs={{span: 12, order: 1}} md={{span: 4, order:1}} className="dark no-pad">
            <div className='info-wrapper'>
              <p className='info-heading'>TOURNAMENT DETAILS</p>
              <p className='info-title'>Tournament has begun!</p>
              <p className='info-desc'>
              Weirdos VS. Scaries is a text-based tournament where you can literally see what your opponent is made of. 
              Fire types are weak to water. Water is no match for grass. And those are just three of the elements competing. <br></br><br></br>Even before the Green Gas the world’s strongest fighters would brawl for sport. 
              Now they fight for honor, glory, and really cool prizes.
              </p>
              <button className='info-button-connect' onClick={handleConnect}>View Progress</button>
              <button className='info-button-deactivate'>Connect your wallet with Desktop</button>
            </div>
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 8, order:2}} className="bg3 no-pad"></Col>
      </Row>
    
    </section>
  )
}

export default TournamentDetails