import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


import MCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function Roadmap() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
  }
  const fetchData = () => {
    return JSON.parse(sessionStorage.getItem("content"));;
  }
  return (
    <section className='darkblue sect-rel no-flow' id='roadmap'>

      <Row className='no-pad'>
        <Col className='no-pad'>
          <div className='info-wrapper-roadmap'>
            <p className='info-heading'>PROJECT ROADMAP</p>
            <p className='info-title-animations'></p>
            <div className='carousel-wrapper'>
              <MCarousel responsive={responsive} partialVisbile={true}	slidesToSlide={1} className="roadmap-carousel">
                <div key="{item}" className='carousel-card-roadmap sect-rel img-wrapper'>
                  <p className='sec-info-card-title'>OCT 2022</p>
                  <p className='sec-info-card-header'>Tournament</p>
                  <p className='sec-info-card-details'>The Scaries Versus Weirdos tournament is a community-focused event with over 40 ETH in prizes. Build the strongest team to win the best prize! </p>
                </div>
                <div key="{item}" className='carousel-card-roadmap sect-rel img-wrapper'>
                  <p className='sec-info-card-title'>Nov 2022</p>
                  <p className='sec-info-card-header'>Animated Series</p>
                  <p className='sec-info-card-details'>Gone are the days of animated shorts. Our animated series will begin telling the tale of Weirdowood in long-form episodes featuring the communities Weirdos and voices.</p>
                </div>
                <div key="{item}" className='carousel-card-roadmap sect-rel img-wrapper'>
                  <p className='sec-info-card-title'>Dec 2022</p>
                  <p className='sec-info-card-header'>Art Basel Miami Beach</p>
                  <p className='sec-info-card-details'>I Like You, You’re Weird heads to Miami to throw a party by the beach accessible only to holders of a Weirdo or Scary. See you there friend.</p>
                </div>
                <div key="{item}" className='carousel-card-roadmap sect-rel img-wrapper'>
                  <p className='sec-info-card-title'>Jan 2023</p>
                  <p className='sec-info-card-header'>Merchandise</p>
                  <p className='sec-info-card-details'>An in-house label of merchandise accessible to Weirdos and Normies alike. The I Like You, You’re Weird art translated into a lifestyle brand.</p>
                </div>
              </MCarousel>
            </div>
            
          </div>
        </Col>
      </Row>
  </section>
  )
}

export default Roadmap