import React, { Component, useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import FighterStats from './FighterStats'
import { db } from '../firebase.js';
// import { collection , addDoc,serverTimestamp} from 'firebase/firestore';
import { collection , query, where, getDocs, addDoc,serverTimestamp, setIndexConfiguration} from 'firebase/firestore';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { setLogLevel } from 'firebase/app';
import {Rounds, set_round} from './rounds';

function Cards(props) {
    const [modaldata, setmodaldata] = useState([]);
    const [weirdos , setWeirdos] = useState(null);
    const [level , setLevel] = useState(0);
    const [sortArray , setSort] = useState([]);
    let contracts = [];
    let metas = [];
    let entries = [];

    var scaries_contract = '0x676bca79a8b7393f6232b440583653231f554185';
    var weirdos_contract = '0x3f93d710ff8c449ea300ad69412e7b8f289a2954';

    const contract1 = "weirdos"; //weirdos
    const contract2 = "scaries"; //scaries scaries

    // sessionStorage.setItem("wallet", "0xD19125f79c6D8C19a4491Fd00bD0E8627891f48b");

    const owner_of = sessionStorage.getItem('wallet');

    const [headValue, setHead] = useState({});
     const [bodyValue, setBody] = useState({});
     const [imgValue, setImg] = useState({});
     const [nameValue, setName] = useState({});
     const [elementValue, setElement] = useState({});

     function handleShow(props) {
        getMetaData();
        
        let data_m = metas.filter(obj => {
            return (obj.contract === props.token_address && obj.token_Id == props.token_id);
          });
          
        let dBody = data_m.find(obj => {
            return (obj.trait_Type == "BODY" || obj.trait_Type == "body")

        });

        if(dBody){
            setBody(dBody);
        }
        //getImage();
        
        let dHead = data_m.find(obj => {
            return (obj.trait_Type == "HEAD" || obj.trait_Type == "head")
        });

        if(dHead){
            setHead(dHead);
        }
        
        setLevel(getRarity(props));
        setName(props.name);
        setImg(props.image);

        setFullscreen(props);
        setShow(true);

      }

      const loadRounds =() =>{
        fetch(Rounds())
             .then((response) => {
                 return response.json();
             })
             .then((actualData) => {
                winners = actualData;
                getSampleEntries();
                getContractData();
             })
             .catch((err) => {
                 console.log(err.message);
             }
         );
    } 

    const getSampleEntries = async () =>{
        let sort = [];
        entries = [];

        const citiesRef = collection(db, "game_entries");
        const q = query(citiesRef, where("entry_number", "==", sessionStorage.getItem('wallet').toLowerCase()));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
        let childSnapshot = doc.data();
        
        sort.push(
            {
            owner_of: childSnapshot.entry_number,
            token_id: childSnapshot.weirdos,
            token_address: weirdos_contract,
            type: "weirdos",
            image: childSnapshot.weirdosImage,
            rarity: childSnapshot.weirdosLevel,
            name: childSnapshot.weirdosName,
            win: getWinners(childSnapshot.weirdosName)
            }
        );

        sort.push(
            {
            owner_of: childSnapshot.entry_number,
            token_id: childSnapshot.scaries,
            token_address: scaries_contract,
            type: "scaries",
            image: childSnapshot.scariesImage,
            rarity: childSnapshot.scariesLevel,
            name: childSnapshot.scariesName,
            win: getWinners(childSnapshot.scariesName)
            }
        );
        setSort(sort);
        });
    }

    const getImage =(data)=>{
       
      try{
           
           let data_ = contracts.filter(obj => {
               return obj.token_address === data.token_address && 
                   obj.token_id === data.token_id;
             });
             
           
           return data_;
       }
       catch(e){
           console.log(e);
       }
   }

   const getMetaData = () => {
    
      
      if(sessionStorage.getItem("metadata")){
          metas = JSON.parse(sessionStorage.getItem("metadata")); 
          getContractData();
          
      }
      else{
        fetch('./data/metadata.json')
           .then((response) => {
               return response.json();
           })
           .then((actualData) => {
              sessionStorage.setItem("metadata", JSON.stringify(actualData))
              metas = actualData;
              getContractData();    
           })
           .catch((err) => {
               console.log(err.message);
           }
       );
          }
          
   
   }

   const getRarity = (data) =>{
    
    let same = 0;
    let mp = data.token_id;
    let data_m = metas.filter(obj => {
      return (obj.contract === data.token_address && obj.token_Id == data.token_id);
    });
    let nftMeta = 0;
    var list = [];

    data_m.map((m, i) => {
      const isFound = list.some(el => {
        return (el.element == m.element);
      })
      if(!isFound){
        list.push({element: m.element});
      }
      else{
        same = 1;
      }
      nftMeta+= m.rarity=="" ? 0: parseFloat(m.rarity);

    });

    nftMeta += same;
    
    return nftMeta;
   }

   let winners = [];

   const getWinners = (name) =>{


    let data_ = winners.filter(obj => {
        return (obj.winner === name);
      });

     // data return collection
     return (data_.length > 0);

   }


   const filterData =(d, type)=>{
       let listItems = [];
       
       if(d.length > 0){
           d.map((obj) => {
               let nft = getImage(obj)[0];
               
               listItems.push(
                   {
                       name: nft.name,
                       token_id: obj.token_id,
                       owner_of: obj.owner_of,
                       token_address: obj.token_address,
                       token_uri: nft.token_uri,
                       rarity: getRarity(obj)
                   }
               )
               });
           setWeirdos(listItems);   
       }
   }

   const getContractData =()=>{
       if(localStorage.getItem("images")){
           contracts = JSON.parse(localStorage.getItem("images"));
           //getData(contract2, "scaries"); 
       }
       else{
           fetch('./data/images.json').then(function(response){
               return response.json();
           }).then(function(myJson) {
               localStorage.setItem("images", JSON.stringify(myJson))
               contracts = myJson; 
   
               //getData(contract2, "scaries"); 
           });
       }
       
       
   }
   const getData = () =>{
       var url = `https://deep-index.moralis.io/api/v2/${owner_of}/nft`;
       var opt = {
           headers: {
               'X-API-Key': 'Gpvk0O7lftQMa0YzOAA29Sr3xkkHk3QSCM3KKckNLuix6aL0Ki6mfHtelebf7gUF'
           }
       }
       var p = '0x676bca79a8b7393f6232b440583653231f554185';
       var p2 = '0x3f93d710ff8c449ea300ad69412e7b8f289a2954';
       fetch(url, opt)
           .then((response) => {
               return response.json();
           })
           .then((actualData) => {
               let nftList = actualData.result;
               let data_ = nftList.filter(obj => {
                   return (obj.token_address === p || obj.token_address == p2);
                 });
                 
               filterData(data_);   
           })
           .catch((err) => {
               console.log(err.message);
           }
       );
   }
     useEffect(()=>{
       getMetaData();
       loadPlayerEntries();
       loadRounds();
     },[])

    const values = [true];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);


    
    
    const handleClose = () => setShow(false);


    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = (record) => {
      setmodaldata(record);
    setIsModalVisible(true);
    };
    let players = [];
    const loadPlayerEntries = () => {
        var obj = sessionStorage.getItem('players');
        
        }
   
    
    
    
    return (
        <div className='cards-position'>
          <div className='row row-card'>
          {
                // use data State Variable For Get Data Use JavaScript Map Mathod
                sortArray? sortArray.map(
                    function(product, i){
                            if(!product.win) {
                                return (

                                    <div className="col center-card no-pad " key={i}>
                                    <div className="">
                                      <Card className='text-center fighter-card'
                                              bg='body-color'
                                              style={{ 
                                                  width: '16rem',
                                                  height: '21rem',
                                                  borderRadius: "20px"
                                              }}
                                              >
                                      
                                        <div className='crd-wrp'>
                                        <Card.Img className='fighter-img2' src={product.image} />
                                              <Card.ImgOverlay className='lvl'>
                                              <Card.Text className='power-lvl'>Level {product.rarity}</Card.Text>
                                              </Card.ImgOverlay>
                                              <Card.ImgOverlay className='fighter-overlay2'>
                                              <Card.Title className='fighter-type'>{product.name}</Card.Title>
                                              </Card.ImgOverlay>
                                        </div>
                                      
                                              <Card.Body className='stats-body'>
                                              <button onClick={ () => handleShow(product) } className='stats-btn' id={product.name}>View Stats</button>
                                                  <Modal 
                                              {...props}
                                              size="xl"
                                              show={show} 
                                              fullscreen={fullscreen} 
                                              onHide={() => setShow(false)}
                                              centered
                                              className='no-pad'
                                              
                                              >
                                              <Modal.Header className='modal-prize-header'>
                                                  
                                                  <div className='mybut'>
                                                  <button onClick={handleClose} className='close-button'>
                                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black" />
                                              </svg>
      
                                                  </button>
                                              </div>
                                              </Modal.Header>      
                                              <Modal.Body className='modal-fighter dark'>
                                                  <Row className='fighter-row test no-pad'>
                                                  <Col sm={4} className='fighter-col right-aligned'>
                                                  {
                                                      
                                                          <div className='fighter-div'>
                                                              <Card className='fight-img-card'>
                                                              <div className='right-aligned'>
                                                              <Card.Img className='fight-img' src={imgValue} />
                                                              <Card.ImgOverlay className='fight-overlay '>
                                                              <Card.Title className='fighter-type'>{nameValue}</Card.Title>
                                                              </Card.ImgOverlay>
                                                              </div>
                                                              </Card>
                                                          </div>         
                                                      
                                                  }
      
                                                  </Col>
                                                  
                                                  <Col sm={4} className='stats-col left-aligned'>
                                                  {
                                                      <div className='fighter-div'>
                                                      <div className='stat-wrap'>
                                                      <p className='fighter-title'>Fighter Stats</p>
                                                          <p className='stats-desc'>HEAD</p>
                                                              <div className='round-div'>{ headValue.trait_Value }<span></span>{headValue.rarity}+</div>
                                                          <p className='stats-desc'>BODY</p>
                                                              <div className='round-div'>{ bodyValue.trait_Value }<span></span>{bodyValue.rarity}+</div>
                                                          <p className='stats-desc'>POWER LEVEL</p>
                                                              <div className='round-div'>   ⚡️Total Power<span></span>LV.{level}</div>
                                                      
                                                      </div>
                                                      </div>
                                                  }
      
                                                  
                                                  </Col>
                                                  </Row>
      
                                              </Modal.Body>
                                                  </Modal>
                                              </Card.Body>
                                      </Card> 
                                      </div>
                                      
                                  </div>
                                      
                              )
                            }
                    }
                ):""
            }
          </div>
        </div>
    );
  }
  
  export default Cards;