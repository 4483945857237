import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, firebaseConfig } from '../firebase.js';

const setUser = (user) =>{
    localStorage.setItem('user', JSON.stringify(user));
}

const isLoggedIn = () => {
    return (localStorage.getItem('user') !== null);
}

export const registerUser = async () => {
    const u = firebaseConfig.messagingSenderId + "@" + firebaseConfig.storageBucket;
    const p = firebaseConfig.apiKey;

    try {
        if(!isLoggedIn()){
            await signInWithEmailAndPassword(auth, u, p);
            setUser(u);
        }
        
      } catch (err) {
        console.error(err);
      }
  };