import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyDwEzj22R3OnRMOFDJOEn2RhtNGb00PM4w",
    authDomain: "nflweb3.firebaseapp.com",
    databaseURL: "https://nflweb3-default-rtdb.firebaseio.com/",
    projectId: "nflweb3",
    storageBucket: "nflweb3.appspot.com",
    messagingSenderId: "314158352103",
    appId: "1:314158352103:web:73fc2a91b6b0f38d2cc442",
    measurementId: "1:314158352103:web:73fc2a91b6b0f38d2cc442",
    vapidKey: "BN4C1t8x4DDZR8q-kmGNCTcuCtzwcJl2Uo2RpYuBzGBrrAhLkOWXUJSHLFLiHX3UqCI1dWeBTlxYLm0U5qNCc3c"
  };

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);
export { db, auth, firebaseConfig, database }