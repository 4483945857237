import React from 'react'
import Animations from './Animations'
import LandingPage from './Landing'
import Mechanics from './Mechanics'
import Prizes from './Prizes'
import Roadmap from './Roadmap'
import SiteFooter from './SiteFooter'
import TournamentDetails from './TournamentDetails'
import Collections from './Collections'
import { Container } from 'react-bootstrap'

function Home() {
  return (
    <Container fluid className='p-0'>
        <LandingPage/>
        <Collections/>
        <Animations/>
        <TournamentDetails/>
        <Prizes/>
        <Mechanics/>
        <Roadmap/>
        <SiteFooter/>
    </Container>
  )
}

export default Home