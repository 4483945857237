import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Prize1 from '../static/images/prize1.png'
import Prize2 from '../static/images/prize2.gif'
import Prize3 from '../static/images/prize3.jpg'
import Prize4 from '../static/images/prize4.png'
import Prize5 from '../static/images/prize5.png'
import PrizeList from './PrizeList.js';

function Prizes() {
  return (
    <section className='dark'>
      <Row className='no-pad'>
          <Col xs={{span: 12, order: 1}} md={{span: 4, order:1}} className="dark no-pad">
            <div className='info-wrapper pr-d'>
              <p className='info-heading'>TOURNAMENT PRIZES</p>
              <p className='info-title'>Winner’s Circle</p>
              <p className='info-desc'>
                View the list of prizes for each placement in the Weirdos Versus Scaries: Season One tournament. 
                <br></br><br></br> In addition to the prizes, each of the top fifty placements will be able to claim an I Like You, You’re Weird merch bundle
                which includes a “My Pet Dino” Trucker Cap and an “ILYYW” Logo Hoodie.
                <br></br><br></br> 50 Prizes to be won.
              </p>
              <div className='prize-hidden'>
                <PrizeList />
              </div>
            </div>
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 8, order:2}} className="centered no-pad">
            <div className='prize-container'>
                <CardGroup className='prize-group'>
                    <Card className='prize-sm'>
                        <Card.Img variant="top" src={Prize1} className="round"/>
                        <Card.ImgOverlay>
                            <Card.Title className='prize-title'>1st place</Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className='prize-sm'>
                        <Card.Img variant="top" src={Prize2} className="round"/>
                        <Card.ImgOverlay>
                            <Card.Title className='prize-title'>2nd place</Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                </CardGroup>
                <CardGroup className='prize-group-mid '>
                    <Card className='prize-md'>
                        <Card.Img variant="top" src={Prize3} className="round"/>
                        <Card.ImgOverlay>
                            <Card.Title className='prize-title'>3rd place</Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                </CardGroup>
                <CardGroup className='prize-group'>
                    <Card className='prize-sm'>
                        <Card.Img variant="top" src={Prize4} className="round"/>
                        <Card.ImgOverlay>
                            <Card.Title className='prize-title'>4th place</Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className='prize-sm'>
                        <Card.Img variant="top" src={Prize5} className="round"/>
                        <Card.ImgOverlay>
                            <Card.Title className='prize-title'>5th place</Card.Title>
                        </Card.ImgOverlay>
                    </Card>
                </CardGroup>   
                
            </div>
            <div className='prize-right-hidden'>
                <PrizeList />
              </div>
        </Col>

      </Row>
    
    </section>
    
  )
}

export default Prizes