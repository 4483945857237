import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import NFTDrag from './NFTDrag';
import Spinner from 'react-bootstrap/Spinner';
import { db, auth, database } from '../firebase.js';
import { useNavigate } from 'react-router-dom';
import { collection , query, where, getDocs, addDoc,serverTimestamp, setIndexConfiguration} from 'firebase/firestore';

 function DragDrop(props) {
  
      
  
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    function handleShow(breakpoint) {
      setFullscreen(breakpoint);
      setShow(true);
    }
    const navigate = useNavigate();
    const [weirdos , setWeirdos] = useState(null);
    const [scaries , setScaries] = useState(null);
    const [isDisabled, setDisabled] = useState(false);
    
    let contracts = [];
    
    const contract1 = "weirdos"; //weirdos
    const contract2 = "scaries"; //scaries
    const owner_of = sessionStorage.getItem('wallet');
    
    let metas = [];
    let entries = [];

    const addEntry = async(e)=>{
      if(isDisabled == true){
        return;
      }
      setDisabled(true);
      e.preventDefault();
      for(var i = 0; i < players.length; i++)
      {
          let entry = players[i];
          await addDoc(collection(db,'game_entries'),{
            entry_number: sessionStorage.getItem("wallet"),
            weirdos: `${entry.weirdos}`,
            weirdosName: `${entry.weirdosName}`,
            weirdosImage: `${entry.weirdosImage}`,
            weirdosLevel: `${entry.weirdosLevel}`,
            scaries: `${entry.scaries}`,
            scariesName: `${entry.scariesName}`,
            scariesImage: `${entry.scariesImage}`,
            scariesLevel: `${entry.scariesLevel}`,
            timestamp: serverTimestamp()
        })
      }
               
      //fire spinner event
      document.getElementById('submitSpin').classList='spinner-screen';
      setTimeout(() => {
        document.getElementById('activeSubmit').classList='hide';
        document.getElementById('successSubmit').classList='submitted';
        setTimeout(()=>{
          document.getElementById('submitSpin').classList='hide';
          navigate("/")
        }, 2000)
      }, 5000);
      players = [];
    };

    const getNft = (data, dtype) => {
        let selectedNft1 = data.filter(obj => {
            return obj.owner_of === owner_of;
          });
        (dtype == "weirdos") ? setWeirdos(selectedNft1) : setScaries(selectedNft1);
    }

    const filterData =(d, type)=>{
      let listItems = [];
      
      if(d.length > 0){
          d.map((obj) => {
              let nft = getImage(obj)[0];
              let en = entries.filter(e => {
                let myown = (type === "scaries") ? e.scaries : e.weirdos;
                return myown === obj.token_id;
              });
              
              if(en.length === 0){
                listItems.push(
                  {
                      name: nft.name,
                      token_id: obj.token_id,
                      owner_of: obj.owner_of,
                      token_address: obj.token_address,
                      token_uri: nft.token_uri,
                      rarity: getRarity(obj)
                  }
               )
              
              }
            }); 
          (type == contract2) ? setScaries(listItems) : setWeirdos(listItems);
      }
  }

    const getContractData =()=>{
      if(localStorage.getItem("images")){
          contracts = JSON.parse(localStorage.getItem("images"));
          getData("vu"); 
      }
      else{
          fetch('./data/images.json').then(function(response){
              return response.json();
          }).then(function(myJson) {
              localStorage.setItem("images", JSON.stringify(myJson))
              contracts = myJson; 
  
              getData("vu"); 
          });
      }
   }
   //document.getElementById('submit').classList+=' hide'

   let sort = [];
   const getSampleEntries = async () =>{
     entries = [];
 
     const citiesRef = collection(db, "game_entries");
     const q = query(citiesRef, where("entry_number", "==", sessionStorage.getItem('wallet')));
     const querySnapshot = await getDocs(q);
 
     querySnapshot.forEach((doc) => {
       let childSnapshot = doc.data();
       
       entries.push(
        {
          weirdos: childSnapshot.weirdos,
          scaries: childSnapshot.scaries
        }
      );

       sort.push(
         {
           owner_of: childSnapshot.entry_number,
           token_id: childSnapshot.weirdos,
           type: "weirdos",
           image: childSnapshot.weirdosImage,
           rarity: childSnapshot.weirdosLevel
         }
       );
 
       sort.push(
         {
           owner_of: childSnapshot.entry_number,
           token_id: childSnapshot.scaries,
           type: "scaries",
           image: childSnapshot.scariesImage,
           rarity: childSnapshot.scariesLevel
         }
       );
 
     });
   }

  const warning = () => {
    document.getElementById('modFooter').classList.toggle('active')
    document.getElementById('footer1').classList+= ' hide';
    document.getElementById('footer2').classList= 'second-footer';
  }
  const cancelSubmit = () => {
    document.getElementById('footer2').classList+= ' hide';
    document.getElementById('modFooter').classList.toggle('active')
    document.getElementById('footer1').classList= 'first-footer';
  }
  
   let players = [];
   let selectedCard = [];

   const addPlayers = (props) =>  {

    let selectedExist = selectedCard.filter(obj =>  {
      return obj.id === props.id && obj.contract === props.contract;
    });
    
    let selectedTypeExist = selectedCard.filter(obj =>  {
      return obj.contract === props.contract;
    });

    if(selectedExist.length===0 && selectedTypeExist.length===0)
      selectedCard.push(props);

    
    let wId = "", wName = "", wImage = "", wLevel = "";
    let sId = "", sName = "", sImage = "", sLevel = "";

    if(selectedCard.length > 1){
      
      for (let i = 0; i < selectedCard.length; i++) {
        var card = selectedCard[i];
        if(card.contract == "weirdos"){
          wId = card.id;
          wName =  card.name;
          wImage = card.url;
          wLevel = card.rarity
        }
        else {
          sId = card.id;
          sName =  card.name;
          sImage = card.url;
          sLevel = card.rarity
        }
      }
      players.push({
        weirdos: wId,
        weirdosName: wName,
        weirdosImage: wImage,
        weirdosLevel: wLevel,
        scaries: sId,
        scariesName: sName,
        scariesImage: sImage,
        scariesLevel: (sLevel) ? sLevel : 0,
      });
      
      selectedCard = [];
      
      const obj1 = document.getElementById("weirdos-box-"+wId);
      const obj2 = document.getElementById("scaries-box-"+sId);
      setTimeout(() => {
        obj1.className="fighter-card hide";
        obj2.className="fighter-card hide";
      }, 100);

      sessionStorage.setItem("players", JSON.stringify(players));
      loadPlayerEntries();
    }
   }

   const loadPlayerEntries = () => {
    var obj = document.getElementById("player-entries");
    var html = "";
    var box_player = document.getElementById("hidden-box");
    var allowSubmit = document.getElementById("submit")

    if(players.length > 0){
      box_player.className = "hidden-box";
      allowSubmit.className = "info-button";
    }
    else{
      allowSubmit.className = "info-button-deactivate";
    }

    

    
    for(var i = 0; i < players.length; i++){
      
      html += `<div class='entry-item-wrapper'>`;
        html += `<div class='entry-item-width'>`;
          html += `<div class='pair-counter'>
                    <p>Pair ${i+1}</p>
                    <span></span> 
                    <button class='remove-button' id=${i}></button>
                  </div>`;
          html += `<div class='pair-wrapper'>`;
            html += `<div class='fighter-card'>`;
              html += `<div class='overlay-wrapper'>`;
                html += `<div class='power'>Level ${players[i].weirdosLevel}</div>`;
                html += `<img class='first-fighter' src=${players[i].weirdosImage} width="125" height="125">`;
              html += `</div>`;
              html += `<p class='fighter-name' style={{paddingTop: "5%"}}>${players[i].weirdosName}</p>`;
            html += `</div>`;
            html += `<div class='fighter-card'>`;
              html += `<div class='overlay-wrapper'>`;
                html += `<div class='power'>Level ${players[i].scariesLevel}</div>`;
                html += `<img class='fighter' src=${players[i].scariesImage} width="125" height="125">`;
              html += `</div>`;
              html += `<p class='fighter-name'>${players[i].scariesName}</p>`;
            html += `</div>`;
          html += `</div>`;
        html += `</div>`;
      html += `</div>`;
    }
    html += box_player.innerHTML;
    obj.innerHTML = html;

    document.getElementById("pcount").innerText = players.length;

    const btns = document.querySelectorAll('.remove-button');
    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener('click', function (e) {
        var idx = e.target.id;

        var player = players[idx];
        document.getElementById("weirdos-box-"+player.weirdos).className='fighter-card';
        document.getElementById("scaries-box-"+player.scaries).className='fighter-card';
        

        players.splice(idx, 1);
        loadPlayerEntries();
      });
    }
    
   }

   let nftList = [];
   let cursor = null;

   const getData = (mcursor) =>{
    
    let url = `https://deep-index.moralis.io/api/v2/${owner_of}/nft`;
    if(mcursor!==null && mcursor!="vu"){
      url = `https://deep-index.moralis.io/api/v2/${owner_of}/nft?cursor=${mcursor}`;
    }
    
    var opt = {
        headers: {
            'X-API-Key': 'Gpvk0O7lftQMa0YzOAA29Sr3xkkHk3QSCM3KKckNLuix6aL0Ki6mfHtelebf7gUF'
        }
    }
    var p = '0x676bca79a8b7393f6232b440583653231f554185';
    var p2 = '0x3f93d710ff8c449ea300ad69412e7b8f289a2954';

    fetch(url, opt)
        .then((response) => {
            return response.json();
        })
        .then((actualData) => {
            if(actualData.message){
              console.log(actualData.message);
              return;
            }

            let result = actualData.result;
            for(var i = 0; i < result.length; i++){
              var list = nftList.filter(obj => {
                return (obj.token_address == result[i].token_address && obj.token_id == result[i].token_id)
              });
              if(list.length==0)
                nftList.push(result[i]);
            }

            if(actualData.cursor !== null){
              cursor = actualData.cursor;
              setTimeout(()=>{
                getData(cursor);
              }, 1000);
              return;
            }
          
            let data_ = nftList.filter(obj => {
                return obj.token_address === p;
              });
              
            let data2_ = nftList.filter(obj => {
                return obj.token_address === p2;
              });

             
            filterData(data_, contract2);  
            filterData(data2_, contract1);  
        })
        .catch((err) => {
            console.log(err.message);
        }
    );
  }

      const getImage =(data)=>{
        try{
             
             let data_ = contracts.filter(obj => {
                 return obj.token_address === data.token_address && 
                     obj.token_id === data.token_id;
               });
               
             return data_;
         }
         catch(e){
             console.log(e);
         }
     }

     const getMetaData = () => {
      if(sessionStorage.getItem("metadata")){
          metas = JSON.parse(sessionStorage.getItem("metadata")); 
          getContractData();
          
      }
      else{
        fetch('./data/metadata.json')
           .then((response) => {
               return response.json();
           })
           .then((actualData) => {
              sessionStorage.setItem("metadata", JSON.stringify(actualData))
              metas = actualData;
              getContractData();    
           })
           .catch((err) => {
               console.log(err.message);
           }
       );
          }
          
   
   }

   const getRarity = (data) =>{
    let same = 0;
    let mp = data.token_id;
    let data_m = metas.filter(obj => {
      return (obj.contract === data.token_address && obj.token_Id == data.token_id);
    });
    let nftMeta = 0;
    var list = [];

    data_m.map((m, i) => {
      const isFound = list.some(el => {
        return (el.element == m.element);
      })
      if(!isFound){
        list.push({element: m.element});
      }
      else{
        same = 1;
      }
      nftMeta+= m.rarity=="" ? 0: parseFloat(m.rarity);
    });
    nftMeta += same;
    return nftMeta;
   }

     
    

     useEffect(()=>{
      getSampleEntries();
      getContractData();
      getMetaData();
      

    },[])

  return (
    <>
        
        <button className='info-button-drag-drop' onClick={handleShow}>Select Team</button>
        <Modal 
          {...props}
          size="xl"
          show={show} 
          fullscreen={fullscreen} 
          onHide={() => setShow(false)}
          centered
          className='no-pad'
        >
        <div className='hide' id='submitSpin'>
        <div className='loading' id='activeSubmit'>
            <p>Submitting Pairs ....</p>
            <div className='overlay-icon'>
                <div className='center-submit'>
                    <svg width="45" height="45" viewBox="0 0 54 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 0.759653L52.7356 23.25L0.5 45.7403V28.8542L38.6378 23.7456V22.7544L0.5 17.6458V0.759653Z" fill="#6F48E7" stroke="#6F48E7"/>
                    </svg>
                </div>
                <Spinner animation="border" className='spinner'/>
            </div>
        </div>
        <div className='hide' id='successSubmit'>
            <p>Success! Pairs have been submited!</p>
            <div className='overlay-icon'>
                <div className='center-submit'>
                    <svg width="56" height="44" viewBox="0 0 56 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M55.1781 5.59156L55.351 5.41611L55.1781 5.24065L50.8272 0.824544L50.6492 0.643871L50.4711 0.82446L17.9713 33.7806L5.52873 21.1823L5.35064 21.0019L5.17277 21.1825L0.821913 25.5986L0.649049 25.774L0.821913 25.9495L17.7933 43.1755L17.9714 43.3562L18.1495 43.1755L55.1781 5.59156Z" fill="#6F48E7" stroke="#6F48E7" strokeWidth="0.5"/>
                    </svg>
                </div>
                <Spinner animation="grow" className='spinner'/>
            </div>
        </div>
    </div>
        <Modal.Header className='modal-prize-header'>
          <div className='mybut'>
          <button onClick={handleClose} className='close-button'>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#000000"  />
          </svg>
          </button>
        </div>
        </Modal.Header>      
        <Modal.Body className='modal-select-team'>
          <Row className='no-pad select-pair-details'>
            <Col className='no-pad'>
              <div className='select-pair-info'>
                <p className='info-heading-grey title-pad'>SUBMISSIONS</p>
                <p className='dash-title header-pad'>Select your pairs</p>
                <p className='dash-desc'>In order to participate in the tournament, you must enter your Weirdos and Scaries in pairs. Choose from your available NFTs below and start pairing up! When you’re ready, press “Submit Pairs” at the bottom of the screen.</p>
              </div>
            </Col>
          </Row>
          <Row className='no-pad select-pair-containers'>
            <Col className='no-pad'>
              <div className='sect-pairs'>
                <Row lg={4} className='entry no-pad' id='player-entries'>
                
                </Row>
                
                <div id="hidden-box">
                  <div className='drop-zone'>   
                    <p className='pair-counter'>Add a Pair</p>
                    <div className='pairs'>
                      <div className='add-weirdo centered'>
                        <div className='centered' style={{paddingBottom: '4%'}}>
                          <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='add'>
                            <path d="M13.3977 7.91487H7.91194V13.4006H6.08337V7.91487H0.597656V6.0863H6.08337V0.600586H7.91194V6.0863H13.3977V7.91487Z" fill="#000000" style={{zIndex: '999'}}/>
                          </svg>
                        </div>
                        <p>Add Weirdo</p>
                      </div>
                      <div className='add-scary centered'>
                        <div className='centered' style={{paddingBottom: '4%'}}>
                          <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='add'>
                            <path d="M13.3977 7.91487H7.91194V13.4006H6.08337V7.91487H0.597656V6.0863H6.08337V0.600586H7.91194V6.0863H13.3977V7.91487Z" fill="#000000"/>
                          </svg>
                        </div>
                        <p>Add Scary</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='no-pad'>
            <Col md className='no-pad'>
              <div className='fighter-container' style={{marginRight: "10px"}}>
                <p className='dash-title'>Your Available Weirdos</p>
                <div className='select-fighter-wrapper'>
                  <div className='drag-item' id="weirdos-box">    
                    {
                // use data State Variable For Get Data Use JavaScript Map Mathod
                weirdos? weirdos.map(
                    function(data, i){
                      const obj = {id: data.token_id, url: data.token_uri, name: data.name, contract: 'weirdos', rarity: data.rarity}
                            return (
                              <NFTDrag key={i} props={obj} addPlayer={addPlayers}/>         
                        )
                    }
                ):""
            }
            
                  </div>
                </div>
              </div>
            </Col>
            <Col md className='no-pad'>
              <div className='fighter-container' style={{marginLeft: "10px"}}>
                <p className='dash-title'>Your Available Scaries</p>
                <div className='select-fighter-wrapper'>
                  <div className='drag-item' id="scaries-box">
                  {
                        // use data State Variable For Get Data Use JavaScript Map Mathod
                        scaries ? scaries.map(
                            function(data, i){
                              const obj2 = {id: data.token_id, url: data.token_uri, name: data.name, contract: 'scaries', rarity: data.rarity}
                                    return (
                                      <NFTDrag key={i} props={obj2} addPlayer={addPlayers}/>
                                )
                            }
                        ):""
                    }
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='modal-select-team-footer no-pad' id='modFooter'>
                    <div className='first-footer' id='footer1'>
                      <div className='footnote'>
                        <p>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.1 11.7H9.9V13.5H8.1V11.7ZM8.1 4.5H9.9V9.9H8.1V4.5ZM9 0C4.023 0 0 4.05 0 9C0 11.3869 0.948211 13.6761 2.63604 15.364C3.47177 16.1997 4.46392 16.8626 5.55585 17.3149C6.64778 17.7672 7.8181 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 0 9 0ZM9 16.2C7.09044 16.2 5.25909 15.4414 3.90883 14.0912C2.55857 12.7409 1.8 10.9096 1.8 9C1.8 7.09044 2.55857 5.25909 3.90883 3.90883C5.25909 2.55857 7.09044 1.8 9 1.8C10.9096 1.8 12.7409 2.55857 14.0912 3.90883C15.4414 5.25909 16.2 7.09044 16.2 9C16.2 10.9096 15.4414 12.7409 14.0912 14.0912C12.7409 15.4414 10.9096 16.2 9 16.2Z" fill="#F02D34"/>
                        </svg>
                        &nbsp;
                        Note:  All pair submissions are final. You will not be able to make any adjustments.        
                        </p>
                      </div>
                      <div className='footnote-func'>
                        <p className='note-pair-counter'><span id="pcount"></span> pairs selected</p>
                        <button className='info-button-deactivate' id='submit' onClick={warning}>Submit Pairs</button> 
                      </div>
                    </div>
                    <div className='second-footer hide' id='footer2'>
                        <p>Are you sure you want to submit?</p>
                        <p>All submissions are final. Transfers will automatically result in disqualification.</p>
                        <button className='info-button' id='submit' onClick={addEntry} disabled={isDisabled}>Submit Pairs</button>
                        <button className='info-button' id='cancel' onClick={cancelSubmit} style={{background: 'transparent'}}>Cancel</button> 
                    </div>
        </Modal.Footer>
      </Modal>
    </>
    
    
  )
}

export default DragDrop