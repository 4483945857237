import React, { Component, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image'
import Navbar from 'react-bootstrap/Navbar';
import useMetaMask from '../hooks/metamask';
import SiteFooter from './SiteFooter';
import { useNavigate } from 'react-router-dom';
import Hero1 from '../assets/images/backgrounds/left-weirdo_1.png'
import Hero2 from '../assets/images/backgrounds/right-scary_1.png'


function LandingPage() {

  const [isAuthenticated , setAuthenticated] = useState(false);
  

  const handleClick = event => {
    //var nv = document.getElementsByClassName(".navbar-collapse");
    var b = event.currentTarget.classList.contains('collapsed');
    document.getElementById("toggle1").className = b ? "toggle-hide" : "toggle-show";
    document.getElementById("toggle2").className = b ? "toggle-show" : "toggle-hide";
    document.activeElement.blur()
  }

  function handleAccountsChanged(accounts) {
    if (accounts.length > 0) {
      setAuthenticated(true);
      document.getElementById("hidden-div").className = "hidden";
    }
  }

  const navigate = useNavigate();
  const buildAccount = () => {
     return "Dashboard";
   }

   const handleConnect = () => {
    navigate("/dashboard");
    try{
      const ethereum = (window).ethereum;
      var address = ethereum.selectedAddress;
      sessionStorage.setItem("wallet", address)
    }
    catch(e){

    }
    
    return;
    /*
    const isAuthenticated = localStorage.getItem("isAuthenticated");
      if(isActive && sessionStorage.getItem("account") !== null){
      
        console.log(isAuthenticated);
         if(isAuthenticated) {
          window.location.href = "/dashboard";
          //navigate("/dashboard");
        }  
      }
      else if(sessionStorage.getItem("account") == null){
        console.log("not active");
       
      }
      else{
        connect();
      }
      */
  }
  function connect2() {
    try{
      const ethereum = (window).ethereum;
      ethereum
      .request({ method: 'eth_requestAccounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        if (err.code === 4001) {
          // EIP-1193 userRejectedRequest error
          // If this happens, the user rejected the connection request.
          console.log('Please connect to MetaMask.');
        } else {
          console.error(err);
        }
      });
    }
    catch(e){

    }
    
  }

  try{
    (window).ethereum.on('accountsChanged', handleAccountsChanged);
  }
  catch(e){}

  useEffect(()=>{
    connect2();
  },[])

  return (
    <section className='darkblue sect-rel  no-overflow'>
       <div className='landing-div'>
        
       <Navbar collapseOnSelect expand="lg" variant="dark" className='navfixed'>
        <Container fluid className='no-pad nav-co'>
        <Navbar.Brand className='logo' href="#home">ILYYW</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleClick}>
          <div id="toggle1" className='toggle-show'><svg width="30" height="30" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0H18V2H0V0ZM0 5H18V7H0V5ZM0 10H18V12H0V10Z" fill="#ffffff"/>
          </svg></div>
          <div id="toggle2" className='toggle-hide'><svg width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#ffffff"/>
          </svg></div>
          </Navbar.Toggle>
        
        <Navbar.Collapse className="center">
          <Nav className="nav-bar">
            <div className='div-explore' id="content-id">EXPLORE</div>
          <div className='nav-wrap'>
            <Nav.Link className="nav-name px-4" href="#collections">Collections</Nav.Link>
            <Nav.Link className="nav-name px-4" href="#animations">Animations</Nav.Link>
            <Nav.Link className="nav-name px-4" href="#tournament">Tournament</Nav.Link>
            <Nav.Link className="nav-name px-4" href="#roadmap">Roadmap</Nav.Link>
          </div>
          <div className='div-wallet'>Connect your wallet with Desktop</div>   
            <div className='nav-foot'>
            <SiteFooter />
            </div>
          </Nav>
          
        </Navbar.Collapse>
        <div className='connect-div'>
          <button id='connect' className='info-button dash-connect' onClick={handleConnect}>
          { isAuthenticated ? <span>{buildAccount()}</span> : <span>Connect Wallet</span> }
          </button>
          </div>
        </Container>
    </Navbar>
       </div>
      
<Row className='sect-landing no-pad'>

       <Col className="bg1 no-pad">
        <Row>
          <Col className='no-pad'>
            <img src={Hero1} className='hero1'></img>
          </Col>
          <Col className='no-pad center-section'>
                <div className='mid-text' id='hidden-div'>
                <div className='text-wrap'>
                  <p className='sect-title'>Congrats Winners!</p>
                  <p className='sect-desc'>
                    Connect your wallet to view the tournament leaderboard.
                  </p>
                <button className='info-button' onClick={handleConnect}>Connect Wallet &nbsp;
                <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="16px" height="16px">
                  <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 5 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z"/>
                </svg>
                 </button>
                </div>
                </div>
            
              </Col>
          <Col className='no-pad'>
            <img src={Hero2} className='hero2'></img>
          </Col>
        </Row>
       </Col>

       <div className='explore-wrapper'>
         <a href='#collections' className='explore'> 
           Explore
           &nbsp;
           <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M12.6658 0.67751L7.32249 6.02084L1.96749 0.67751L0.322495 2.32251L7.32249 9.32251L14.3225 2.32251L12.6658 0.67751Z" fill="white"/>
           </svg>
         </a>
       </div>
     </Row>

    </section>
  );
}

export default LandingPage;