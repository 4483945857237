import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Countdown from 'react-countdown';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Mechanics from './Mechanics';
import Image from 'react-bootstrap/Image'
import image1 from '../static/images/prize1.png'
import image2 from '../static/images/prize2.png'
import image3 from '../static/images/prize3.jpg'
import image4 from '../static/images/prize4.png'
import image5 from '../static/images/prize5.png'
import image6 from '../static/images/maxpainandfrens.png'
import image7 from '../static/images/ledgergenpass.png'
import image8 from '../static/images/cosmiccutie.png'
import image9 from '../static/images/arcadeland.png'
import image10 from '../static/images/dippie.png'
import image11 from '../static/images/weirdo744.png'
import image12 from '../static/images/weirdo3364.png'
import image13 from '../static/images/ts6467.png'
import image14 from '../static/images/ts6505.png'
import image15 from '../static/images/ts7807.png'
import image16 from '../static/images/spotthekekhirst.png'
import image17 from '../static/images/haus.png'
import image18 from '../static/images/qmark.png'
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Prize1 from '../static/images/prize1.png'
import Prize2 from '../static/images/prize2.gif'
import Prize3 from '../static/images/prize3.jpg'
import Prize4 from '../static/images/prize4.png'
import Prize5 from '../static/images/prize5.png'
import SiteFooter from './SiteFooter';
import Cards from './Cards';
import DragDrop from './DragDrop';
import CountdownTimer from './CountdownTimer';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import ElimCards from './ElimCards'
import RoundList from './RoundList';
import TourneyBracket from './TourneyBracket';
import {Rounds, round_number} from './rounds';
import { db } from '../firebase.js';
// import { collection , addDoc,serverTimestamp} from 'firebase/firestore';
import { collection , query, where, getDocs, addDoc,serverTimestamp, setIndexConfiguration} from 'firebase/firestore';

const queryClient = new QueryClient()

function Dashboard_Select_Team(props) {

  const [key, setKey] = useState('home');
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [rank, setRank] = useState();

  //const THREE_DAYS_IN_MS = 2 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  let x = new Date();
  let y = new Date("Nov 04, 2022 15:00:00");

  let seconds = Math.abs(x.getTime() - y.getTime())/1000;
  const THREE_DAYS_IN_MS = seconds * 1000;

  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  //sessionStorage.setItem('wallet',"0xed19Dc5A34e3523D9CdFad74517e7725BAEa15E7");

  const owner_of = sessionStorage.getItem('wallet');

    // Random component
const Completionist = () => <span>Entry period is now closed</span>;

const datediff = (first, second) =>{        
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}
const parseDate =(str)=> {
  let mdy = str.stri('/');
  return new Date(mdy[2], mdy[0] - 1, mdy[1]);
}
// Renderer callback with condition
const renderer = ({days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return(
        <div className='countdown-wrapper'>
            <div className='timer-card centered'>  
                {days} 
                <p className='timer-var'>DAYS</p>
            </div>
            <div className='timer-card centered'>  
                {hours} 
                <p className='timer-var'>HOURS</p>
            </div>
            <div className='timer-card centered'>
                {minutes} 
                <p className='timer-var'>MINUTES</p>
            </div>
            <div className='timer-card centered'>
                {seconds} 
                <p className='timer-var'>SECONDS</p>
            </div>
        </div>
    );
  }
};

let winners = [];

   const getWinners = (name) =>{
    let data_ = winners.filter(obj => {
        return (obj.winner === name);
      });

     
     return (data_.length > 0);

   }

const getSampleEntries = async () =>{
  sessionStorage.setItem("rank", "false");

  let sort = [];

  const citiesRef = collection(db, "game_entries");
  const q = query(citiesRef, where("entry_number", "==", owner_of.toLowerCase()));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
  let childSnapshot = doc.data();
  
  sort.push(
      {
      win: getWinners(childSnapshot.weirdosName)
      }
  );

  sort.push(
      {
      win: getWinners(childSnapshot.scariesName)
      }
  );

  
  
  });

  var mywinner = sort.filter(obj => {
    return (obj.win === true);
  });
  
  if(mywinner.length > 0){
      sessionStorage.setItem("rank", "true");
  }

  getRank(winners);
}

const loadRounds = () =>{
  fetch(Rounds())
       .then((response) => {
           return response.json();
       })
       .then((actualData) => {
        winners = actualData;
        getSampleEntries();
       })
       .catch((err) => {
           console.log(err.message);
       }
   );
  } 

  const getRank = (d) => {
    let win = sessionStorage.getItem("rank");
    if(win==="true") {
        setRank("Top " + d.length);
      }
    else {
        setRank(<span style={{color: "darkred"}}>Eliminated</span>);
      }
  }



  const data  = [
    {
      "name": "Doodle #6901",
      "placement": "1st place",
      "image": image1
    },
    {
      "name": "Invisible Friend #1280",
      "placement": "2nd place",
      "image": image2
    },
    {
      "name": "Otherdeed for Otherside #75336",
      "placement": "3rd place",
      "image": image3
    },
    {
      "name": "goblintown #1971",
      "placement": "4th place",
      "image": image4
    },
    {
      "name": "Murakami Flower #8138",
      "placement": "5th place",
      "image": image5
    },
    {
      "name": "MAX PAIN AND FRENDS BY XCOPY",
      "placement": "6th place",
      "image": image6
    },
    {
      "name": "MAX PAIN AND FRENDS BY XCOPY",
      "placement": "7th place",
      "image": image6
    },
    {
      "name": "MAX PAIN AND FRENDS BY XCOPY",
      "placement": "8th place",
      "image": image6
    },
    {
      "name": "MAX PAIN AND FRENDS BY XCOPY",
      "placement": "9th place",
      "image": image6
    },
    {
      "name": "MAX PAIN AND FRENDS BY XCOPY",
      "placement": "10th place",
      "image": image6
    },
    {
      "name": "[Ledger] Market Pass- Genesis Edition",
      "placement": "11th place",
      "image": image7
    },
    {
      "name": "[Ledger] Market Pass- Genesis Edition",
      "placement": "12th place",
      "image": image7
    },
    {
      "name": "[Ledger] Market Pass- Genesis Edition",
      "placement": "13th place",
      "image": image7
    },
    {
      "name": "[Ledger] Market Pass- Genesis Edition",
      "placement": "14th place",
      "image": image7
    },
    {
      "name": "[Ledger] Market Pass- Genesis Edition",
      "placement": "15th place",
      "image": image7
    },
    {
      "name": "Cosmic Cutie #72",
      "placement": "16th place",
      "image": image8
    },
    {
      "name": "TBA",
      "placement": "17th place",
      "image": image18
    },
    {
      "name": "TBA",
      "placement": "18th place",
      "image": image18
    },
    {
      "name": "TBA",
      "placement": "19th place",
      "image": image18
    },
    {
      "name": "TBA",
      "placement": "20th place",
      "image": image18
    },
    {
      "name": "SPOT THE KEKHIRST",
      "placement": "21st place",
      "image": image16
    },
    {
      "name": "TBA",
      "placement": "22nd place",
      "image": image18
    },
    {
      "name": "Arcade Land X-Large #1005",
      "placement": "23rd place",
      "image": image9
    },
    {
      "name": "TBA",
      "placement": "24th plcae",
      "image": image18
    },
    {
      "name": "Dippie #6030",
      "placement": "25th place",
      "image": image10
    },
    {
      "name": "Weirdo #744",
      "placement": "26th place",
      "image": image11
    },
    {
      "name": "Weirdo #3364",
      "placement": "27th place",
      "image": image12
    },
    {
      "name": "Test Subject #6467",
      "placement": "28th place",
      "image": image13
    },
    {
      "name": "Test Subject #6505",
      "placement": "29th place",
      "image": image14
    },
    {
      "name": "Test Subject #7807",
      "placement": "30th place",
      "image": image15
    },
    {
      "name": "hausphases by Haus",
      "placement": "31st place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "32nd place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "33rd place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "34th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "35th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "36th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "37th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "38th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "39th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "40th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "41st place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "42nd place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "43rd place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "44th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "45th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "46th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "47th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "48th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "49th place",
      "image": image17
    },
    {
      "name": "hausphases by Haus",
      "placement": "50th place",
      "image": image17
    }
  ];

  

  


  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  var acct = sessionStorage.getItem("wallet");
  var walletID = acct.substring(0,6) + "..." + acct.substring(acct.length-4,acct.length);
  let rounds = "Round " + round_number;

  useEffect(()=>{
    setTimeout(()=>{
      //getRank()
      loadRounds();
      
    },1000)
  },[])
  
  return (
    <section className='dark' id='tournament'>
      <div className='wallet-id'>
      <div className='info-button-id'>{walletID}</div>
      </div>
      <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="tab-wrapper"
    >
      <Tab eventKey="home" title="Your Stats" className='tab-item'>
        <section className='active-fighters black'>
          <Row className='bg-team no-pad sel-team'>
              <Col xs={{span: 12, order: 2}} md={{span: 4, order:1}} className='no-pad'>
                  <div className='col-text'>Your active fighters</div>
              </Col>
              <Col xs={{span: 12, order: 2}} md={{span: 8, order:2}} className='box-col no-pad'>
                <div className='box-wrp'>
                <Row className='rank-box'>
                <Col sm={4} className='left-aligned no-pad'>
                    <div className=''>
                    <svg className='trophy' width="82" height="72" viewBox="0 0 85 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M74.2441 3.63852C71.6002 3.05594 68.547 3.42955 65.6724 4.50923C65.6972 2.96159 65.6896 1.45007 65.6458 0H16.3546C16.3107 1.44948 16.3025 2.96108 16.3279 4.50923C13.4533 3.42955 10.4008 3.05595 7.75627 3.63852C3.84581 4.4953 1.19057 7.17331 0.280436 11.1722C-0.908664 16.3933 1.70913 22.3793 7.6502 28.0299C12.3315 32.481 18.612 36.1842 25.2444 38.4714C27.9926 42.3315 31.5103 45.4414 35.9736 47.3323C35.3228 49.7005 33.0387 56.5618 27.5196 60.7607H54.4804C48.96 56.5624 46.6766 49.7005 46.0264 47.3323C50.4899 45.4414 54.0087 42.3309 56.7556 38.4701C63.3875 36.1835 69.6685 32.4803 74.3498 28.0293C80.2915 22.3783 82.9086 16.3929 81.7196 11.1716C80.8082 7.17331 78.1535 4.49527 74.2431 3.63852H74.2441ZM11.6051 23.9022C7.30822 19.8133 5.16255 15.5283 5.86671 12.4367C6.28046 10.6116 7.27316 9.58776 8.98971 9.21037C11.2555 8.71202 14.2114 9.46938 16.6461 10.9696C17.1991 17.5141 18.4968 24.3486 20.9246 30.4129C17.431 28.6025 14.2216 26.3912 11.6051 23.902V23.9022ZM70.3946 23.9022C67.7781 26.3902 64.5692 28.6027 61.0751 30.4138C63.5042 24.3499 64.8012 17.5147 65.3536 10.9704C67.7883 9.47088 70.7436 8.71351 73.01 9.21119C74.7266 9.58859 75.7199 10.6125 76.133 12.4375C76.8372 15.5295 74.6916 19.8147 70.3946 23.9023V23.9022Z" fill="white"/>
                    <path d="M23.6836 64.543H58.3059V72H23.6836V64.543Z" fill="white"/>
                    </svg>
                    </div>
                  </Col>
           
                  <Col sm={6} className='left-aligned no-pad'>
                    <div>
                    <p className='rank-position'>{rank}</p>
                    <p className='rank-desc'>Current Rank</p>
                    </div>
                  </Col>
                </Row>
                </div>
              </Col> 
          </Row>
          
          <Row className='no-pad'>
            <Col className="no-pad">
                <Cards />
            </Col>
          </Row>
          <Row>
            <div className='cards-title'>Your eliminated fighters</div>
              <Col className="elim-fighters no-pad">
                <ElimCards/>
              </Col>
          </Row>
        </section>
      </Tab>

      <Tab eventKey="bracket" title={rounds} className='tab-item'>
        <section>          
          <RoundList/>
        </section>
      </Tab>

      <Tab eventKey="tourneybracket" title="Top 12 Bracket" className='tab-item'>
        <section>          
          <TourneyBracket/>
        </section>
      </Tab>

      <Tab eventKey="prizes" title="Tournament Prizes" className='tab-item'>
        <section className='dark dash-sect-wrapper'>
          <Row className='no-pad'>
              <Col xs={{span: 12, order: 1}} md={{span: 4, order:1}} className="dark no-pad">
                <div className='info-wrapper pr-d'>
                  <p className='info-heading'>TOURNAMENT PRIZES</p>
                  <p className='info-title'>Winner’s Circle</p>
                  <p className='info-desc'>
                    View the list of prizes for each placement in the Weirdos Versus Scaries: Season One tournament. 
                    <br></br><br></br> In addition to the prizes, each of the top fifty placements will be able to claim an I Like You, You’re Weird merch bundle
                    which includes a “My Pet Dino” Trucker Cap and an “ILYYW” Logo Hoodie.
                    <br></br><br></br> 50 Prizes to be won.
                  </p>
                </div>
              </Col>
              <Col xs={{span: 12, order: 2}} md={{span: 8, order:2}} className="centered no-pad">
                <div className='prize-container'>
                    <CardGroup className='prize-group'>
                        <Card className='prize-sm'>
                            <Card.Img variant="top" src={Prize1} className="round"/>
                            <Card.ImgOverlay>
                                <Card.Title className='prize-title'>1st place</Card.Title>
                            </Card.ImgOverlay>
                        </Card>
                        <Card className='prize-sm'>
                            <Card.Img variant="top" src={Prize2} className="round"/>
                            <Card.ImgOverlay>
                                <Card.Title className='prize-title'>2nd place</Card.Title>
                            </Card.ImgOverlay>
                        </Card>
                    </CardGroup>
                    <CardGroup className='prize-group-mid '>
                        <Card className='prize-md'>
                            <Card.Img variant="top" src={Prize3} className="round"/>
                            <Card.ImgOverlay>
                                <Card.Title className='prize-title'>3rd place</Card.Title>
                            </Card.ImgOverlay>
                        </Card>
                    </CardGroup>
                    <CardGroup className='prize-group'>
                        <Card className='prize-sm'>
                            <Card.Img variant="top" src={Prize4} className="round"/>
                            <Card.ImgOverlay>
                                <Card.Title className='prize-title'>4th place</Card.Title>
                            </Card.ImgOverlay>
                        </Card>
                        <Card className='prize-sm'>
                            <Card.Img variant="top" src={Prize5} className="round"/>
                            <Card.ImgOverlay>
                                <Card.Title className='prize-title'>5th place</Card.Title>
                            </Card.ImgOverlay>
                        </Card>
                    </CardGroup>   
                </div>
            </Col>
          </Row>
          <Row className='test no-pad list-prize'>
              <Col sm={5} className=' prize-col'>
              {data.map((name, index) => {
                if(index < 25)
                  return (
                    <div className='prize-div' key={index.toString()}>
                      <div className='prize-img-wrapper'>
                        <Image className='prize-img' src={name.image}/>
                      </div>
                      <div className=' prize-info'>
                        <div className=' prize-place'>
                        <p style={{fontSize: 12}}>{name.placement}</p>
                        </div>
                        <div className=' prize-name'>
                          <h4 style={{fontWeight: 600, color: '#c0c0c0'}}>{name.name}</h4>
                        </div>
                      </div>
                    </div>
                    
                  )
              })}
              </Col>
              <Col sm={5} className=' prize-col'>
              {data.map((name, index) => {
                if(index >= 25)
                  return (
                    <div className='prize-div' key={index.toString()}>
                      <div className='prize-img-wrapper'>
                        <Image className='prize-img' src={name.image}/>
                      </div>
                      <div className='prize-info'>
                        <p style={{fontSize: 12, paddingBottom: 8}}>{name.placement}</p>
                        <h4 style={{fontWeight: 600, fontSize: 24, color: '#c0c0c0'}}>{name.name}</h4>
                      </div>
                    </div>
                  )
              })}
              </Col>
          </Row>
        </section>
      </Tab>


      <Tab eventKey="key" title="Battle Key" className='tab-item'>
        <Mechanics/>
      </Tab>
  
      </Tabs>
      <SiteFooter/>
    </section>
  )
}

export default Dashboard_Select_Team