import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import thumbnail1 from '../assets/images/vid_thumbnails/Skit-1-thumbnail.jpg'
import thumbnail2 from '../assets/images/vid_thumbnails/Skit-2-thumbnail.jpg'
import thumbnail3 from '../assets/images/vid_thumbnails/Skit-3-thumbnail.jpg'
import thumbnail4 from '../assets/images/vid_thumbnails/Skit-4-thumbnail.jpg'
import thumbnail5 from '../assets/images/vid_thumbnails/Skit-5-thumbnail.jpg'
import thumbnail6 from '../assets/images/vid_thumbnails/Skit-6-thumbnail.jpg'
import thumbnail7 from '../assets/images/vid_thumbnails/Skit-7-thumbnail.jpg'
import thumbnail8 from '../assets/images/vid_thumbnails/Skit-8-thumbnail.jpg'
import thumbnail9 from '../assets/images/vid_thumbnails/Skit-9-thumbnail.png'

function Animations() {
  let startIndex = 0;
  var data = [
      {
          "thumbnail": "assets/images/vid_thumbnails/Skit-9-thumbnail.png",
          "url":  "assets/videos/ILYYW_S9.mov",
          "caption": "09 - Weirdowood",
          "id": 9
      },
      {
          "thumbnail": "assets/images/vid_thumbnails/Skit-8-thumbnail.jpg",
          "url":  "assets/videos/ILYYW_S8.mp4",
          "caption": "08 - Fight, Run, Cry",
          "id": 8
      },
      {
          "thumbnail": "assets/images/vid_thumbnails/Skit-7-thumbnail.jpg",
          "url":  "assets/videos/ILYYW_S7.mp4",
          "caption": "07 – Nothing but Trouble",
          "id": 7
      },
      {
          "thumbnail": "assets/images/vid_thumbnails/Skit-6-thumbnail.jpg",
          "url":  "assets/videos/ILYYW_S6.mp4",
          "caption": "06 – She is a Hero",
          "id": 6
      },
      {
          "thumbnail": "assets/images/vid_thumbnails/Skit-5-thumbnail.jpg",
          "url":  "assets/videos/ILYYW_S5.mov",
          "caption": "05 – Spooktacular!",
          "id": 5
      },
      {
          "thumbnail": "assets/images/vid_thumbnails/Skit-4-thumbnail.jpg",
          "url":  "assets/videos/ILYYW_S4.mp4",
          "caption": "04 - Cursed Rainbows",
          "id": 4
      },
      {
          "thumbnail": "assets/images/vid_thumbnails/Skit-3-thumbnail.jpg",
          "url":  "assets/videos/ILYYW_S3.mp4",
          "caption": "03 - To New Beginnings",
          "id": 3
      },
      {
          "thumbnail": "assets/images/vid_thumbnails/Skit-2-thumbnail.jpg",
          "url":  "assets/videos/ILYYW_S2.mp4",
          "caption": "02 - S.O.S",
          "id": 2
      },
      {
          "thumbnail": "assets/images/vid_thumbnails/Skit-1-thumbnail.jpg",
          "url":  "assets/videos/ILYYW_S1.mp4",
          "caption": "01 - Any Other Day",
          "id": 1
      }
  ]
  const [startTime, setStartTime] = React.useState(0);
  //const [startIndex, setStartIndex] = React.useState('');

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
  }
  const fetchData = () => {
    //return JSON.parse(sessionStorage.getItem("content"));

    fetch('data.json'
      ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      }
      )
        .then(function(response){
          return response.json();
        })
        .then(function(myJson) {
          return myJson;
          
        });
  }

  const handleVideoShow = (i) => {   
    handleShow();
    startIndex = i;
    returnVideo();
  };

  const returnVideo = () => {    
    var attribute = data[startIndex].url;
    
    setStartTime(attribute);
  }

  const navigateVideo = (todo) => {
    var i = startIndex;
    if(todo == "next"){
      i++;
    }
    else{
      i--;
    }

    //setStartIndex(i);
    returnVideo();
  };

  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <section className='dark sect-rel no-flow' id='animations'>
      <div className='card-gradient-right-roadmap no-pad'></div>
        <Row className='no-pad'>
          <Col className='no-pad'>
            <div className='info-wrapper-animations'>
              <p className='info-heading'>ANIMATED SHORTS</p>
              <p className='info-title-animations'>Latest Animations</p>
              <div className='carousel-wrapper'>
                <MCarousel responsive={responsive} partialVisbile={true}	slidesToSlide={1} className="">
                  <div key="{index}" className='carousel-card  sect-rel img-wrapper'>
                    <div className='carousel-card-overlay' onClick={() => handleVideoShow(0)}>
                      <p className='vid-caption'>09 - Weirdowood</p>
                      <Button variant="primary" className="vid-play" onClick={() => handleVideoShow(0)}>
                        <svg width="16" height="18" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{opacity: 1}}>
                          <path d="M0.5 0V14L11.5 7L0.5 0Z" fill="black"/>
                        </svg>
                      </Button>
                    </div>
                    <Image className='thumbnail' src={thumbnail9}/>
                  </div>
                  <div key="{index}" className='carousel-card  sect-rel img-wrapper'>
                    <div className='carousel-card-overlay' onClick={() => handleVideoShow(1)}>
                      <p className='vid-caption'>08 - Fight, Run, Cry</p>
                      <Button variant="primary" className="vid-play" onClick={() => handleVideoShow(1)}>
                        <svg width="16" height="18" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{opacity: 1}}>
                          <path d="M0.5 0V14L11.5 7L0.5 0Z" fill="black"/>
                        </svg>
                      </Button>
                    </div>
                    <Image className='thumbnail' src={thumbnail8}/>
                  </div>
                  <div key="{index}" className='carousel-card  sect-rel img-wrapper'>
                    <div className='carousel-card-overlay' onClick={() => handleVideoShow(2)}>
                      <p className='vid-caption'>07 – Nothing but Trouble</p>
                      <Button variant="primary" className="vid-play" onClick={() => handleVideoShow(2)}>
                        <svg width="16" height="18" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{opacity: 1}}>
                          <path d="M0.5 0V14L11.5 7L0.5 0Z" fill="black"/>
                        </svg>
                      </Button>
                    </div>
                    <Image className='thumbnail' src={thumbnail7}/>
                  </div>
                  <div key="{index}" className='carousel-card  sect-rel img-wrapper'>
                    <div className='carousel-card-overlay' onClick={() => handleVideoShow(3)}>
                      <p className='vid-caption'>06 – She is a Hero</p>
                      <Button variant="primary" className="vid-play" onClick={() => handleVideoShow(3)}>
                        <svg width="16" height="18" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{opacity: 1}}>
                          <path d="M0.5 0V14L11.5 7L0.5 0Z" fill="black"/>
                        </svg>
                      </Button>
                    </div>
                    <Image className='thumbnail' src={thumbnail6}/>
                  </div>
                  <div key="{index}" className='carousel-card  sect-rel img-wrapper'>
                    <div className='carousel-card-overlay' onClick={() => handleVideoShow(4)}>
                      <p className='vid-caption'>05 – Spooktacular!</p>
                      <Button variant="primary" className="vid-play" onClick={() => handleVideoShow(4)}>
                        <svg width="16" height="18" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{opacity: 1}}>
                          <path d="M0.5 0V14L11.5 7L0.5 0Z" fill="black"/>
                        </svg>
                      </Button>
                    </div>
                    <Image className='thumbnail' src={thumbnail5}/>
                  </div>
                  <div key="{index}" className='carousel-card  sect-rel img-wrapper'>
                    <div className='carousel-card-overlay' onClick={() => handleVideoShow(5)}>
                      <p className='vid-caption'>04 - Cursed Rainbows</p>
                      <Button variant="primary" className="vid-play" onClick={() => handleVideoShow(5)}>
                        <svg width="16" height="18" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{opacity: 1}}>
                          <path d="M0.5 0V14L11.5 7L0.5 0Z" fill="black"/>
                        </svg>
                      </Button>
                    </div>
                    <Image className='thumbnail' src={thumbnail4}/>
                  </div>
                  <div key="{index}" className='carousel-card  sect-rel img-wrapper'>
                    <div className='carousel-card-overlay' onClick={() => handleVideoShow(6)}>
                      <p className='vid-caption'>03 - To New Beginnings</p>
                      <Button variant="primary" className="vid-play" onClick={() => handleVideoShow(6)}>
                        <svg width="16" height="18" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{opacity: 1}}>
                          <path d="M0.5 0V14L11.5 7L0.5 0Z" fill="black"/>
                        </svg>
                      </Button>
                    </div>
                    <Image className='thumbnail' src={thumbnail3}/>
                  </div>
                  <div key="{index}" className='carousel-card  sect-rel img-wrapper'>
                    <div className='carousel-card-overlay' onClick={() => handleVideoShow(7)}>
                      <p className='vid-caption'>02 - S.O.S</p>
                      <Button variant="primary" className="vid-play" onClick={() => handleVideoShow(7)}>
                        <svg width="16" height="18" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{opacity: 1}}>
                          <path d="M0.5 0V14L11.5 7L0.5 0Z" fill="black"/>
                        </svg>
                      </Button>
                    </div>
                    <Image className='thumbnail' src={thumbnail2}/>
                  </div>
                  <div key="{index}" className='carousel-card  sect-rel img-wrapper'>
                    <div className='carousel-card-overlay' onClick={() => handleVideoShow(8)}>
                      <p className='vid-caption'>01 - Any Other Day</p>
                      <Button variant="primary" className="vid-play" onClick={() => handleVideoShow(8)}>
                        <svg width="16" height="18" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{opacity: 1}}>
                          <path d="M0.5 0V14L11.5 7L0.5 0Z" fill="black"/>
                        </svg>
                      </Button>
                    </div>
                    <Image className='thumbnail' src={thumbnail1}/>
                  </div>
                </MCarousel>
              </div>
              
            </div>
          </Col>
        </Row>
                 
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton className='modal-video-header'></Modal.Header>

          <Modal.Body className='modal-video-body'>
            <div className='video-container' data-volume-level="high">
              <video autoPlay className='video-item' id="vid1" src={startTime} controls></video>
            </div>
          </Modal.Body>
        </Modal>
    </section>

    
  )
}

export default Animations